import { useRef, useState } from "react";
import "./contact.scss";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";

const variants = {
  initial: {
    y: 500,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
};

const Contact = () => {
  const formRef = useRef();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);

  const services = [
    "Commercials",
    "Real Estate",
    "Product Commercials",
    "Content Creation & Marketing",
    "Drone Shooting",
    "Corporate Events & Custom Services",
  ];

  const handleServiceChange = (service) => {
    setSelectedServices((prev) =>
        prev.includes(service)
            ? prev.filter((s) => s !== service)
            : [...prev, service]
    );
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    formData.append("selectedServices", selectedServices.join(", "));

    emailjs
        .sendForm(
            "service_5szusoi", // Replace with your Email.js service ID
            "template_8zm1jyp", // Replace with your Email.js template ID
            formRef.current, // Form reference
            "oTas9QxwqwL8fmDEq" // Replace with your Email.js public key
        )
        .then(
            () => {
              setSuccess(true);
              setError(false);
            },
            () => {
              setError(true);
              setSuccess(false);
            }
        );
  };

  return (
      <motion.div className="contact" variants={variants} initial="initial" animate="animate">
        <motion.div className="textContainer" variants={variants}>
          <motion.h1 variants={variants}>
            Let’s work <span className="orange">together</span>
          </motion.h1>
          <motion.div className="item" variants={variants}>
            <h2>Mail</h2>
            <span>office@sptecrumstudios.ro</span>
          </motion.div>
          <motion.div className="item" variants={variants}>
            <h2>Address</h2>
            <span>Strada Vasile Alecsandri 1, Oradea</span>
          </motion.div>
          <motion.div className="item" variants={variants}>
            <h2>Phone</h2>
            <a href="tel:+40 (720) 909 792">+40 (720) 909 792</a>
          </motion.div>
        </motion.div>

        <div className="formContainer">
          <motion.div
              className="phoneSvg"
              initial={{ opacity: 1 }}
              animate={{ opacity: 0 }}
              transition={{ delay: 3, duration: 1 }}
          >
            <svg width="450px" height="450px" viewBox="0 0 32.666 32.666">
              <motion.path
                  strokeWidth={0.2}
                  fill="none"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 3 }}
                  d="M28.189,16.504h-1.666c0-5.437-4.422-9.858-9.856-9.858l-0.001-1.664C23.021,4.979,28.189,10.149,28.189,16.504z
                M16.666,7.856L16.665,9.52c3.853,0,6.983,3.133,6.981,6.983l1.666-0.001C25.312,11.735,21.436,7.856,16.666,7.856z M16.333,0
                C7.326,0,0,7.326,0,16.334c0,9.006,7.326,16.332,16.333,16.332c0.557,0,1.007-0.45,1.007-1.006c0-0.559-0.45-1.01-1.007-1.01
                c-7.896,0-14.318-6.424-14.318-14.316c0-7.896,6.422-14.319,14.318-14.319c7.896,0,14.317,6.424,14.317,14.319
                c0,3.299-1.756,6.568-4.269,7.954c-0.913,0.502-1.903,0.751-2.959,0.761c0.634-0.377,1.183-0.887,1.591-1.529
                c0.08-0.121,0.186-0.228,0.238-0.359c0.328-0.789,0.357-1.684,0.555-2.518c0.243-1.064-4.658-3.143-5.084-1.814
                c-0.154,0.492-0.39,2.048-0.699,2.458c-0.275,0.366-0.953,0.192-1.377-0.168c-1.117-0.952-2.364-2.351-3.458-3.457l0.002-0.001
                c-0.028-0.029-0.062-0.061-0.092-0.092c-0.031-0.029-0.062-0.062-0.093-0.092v0.002c-1.106-1.096-2.506-2.34-3.457-3.459
                c-0.36-0.424-0.534-1.102-0.168-1.377c0.41-0.311,1.966-0.543,2.458-0.699c1.326-0.424-0.75-5.328-1.816-5.084
                c-0.832,0.195-1.727,0.227-2.516,0.553c-0.134,0.057-0.238,0.16-0.359,0.24c-2.799,1.774-3.16,6.082-0.428,9.292
                c1.041,1.228,2.127,2.416,3.245,3.576l-0.006,0.004c0.031,0.031,0.063,0.06,0.095,0.09c0.03,0.031,0.059,0.062,0.088,0.095
                l0.006-0.006c1.16,1.118,2.535,2.765,4.769,4.255c4.703,3.141,8.312,2.264,10.438,1.098c3.67-2.021,5.312-6.338,5.312-9.719
                C32.666,7.326,25.339,0,16.333,0z"
              />
            </svg>
          </motion.div>

          <motion.form
              ref={formRef}
              onSubmit={sendEmail}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 4, duration: 1 }}
          >
            <input type="text" required placeholder="Name" name="name" />
            <input type="email" required placeholder="Email" name="email" />
            <div className={`dropdown ${isDropdownOpen ? "open" : ""}`}
                 onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <div className="dropdown-button flex items-center justify-between">
  <span className="flex-grow">
    {selectedServices.length > 0
        ? selectedServices.join(", ")
        : "Select Services"}
  </span>
                <span className={`arrow ${isDropdownOpen ? "open" : "closed"} ml-2`}>
    {isDropdownOpen ? "▼" : "◀"}
  </span>
              </div>
              {isDropdownOpen && (
                  <ul className="dropdown-content">
                    {services.map((service) => (
                        <li
                            key={service}
                            className={`dropdown-item ${
                                selectedServices.includes(service) ? "selected" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleServiceChange(service);
                            }}
                        >
                          {service}
                          {selectedServices.includes(service) && (
                              <span className="checkmark">✔</span>
                          )}
                        </li>
                    ))}
                  </ul>
              )}
            </div>
            <textarea rows={4} placeholder="Message" name="message" />
            <button className="text-2xl text-white">SUBMIT</button>
            {error && <p className="error">Something went wrong. Please try again.</p>}
            {success && <p className="success">Your message was sent successfully!</p>}
          </motion.form>
        </div>
      </motion.div>
  );
};

export default Contact;
