import Sidebar from "../sidebar/Sidebar";
import "./navbar.scss";

const Navbar = () => {
  return (
      <div className="navbar">
        <Sidebar />
      </div>
  );
};

export default Navbar;
